import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  visible: boolean
  isQRCodeScanned: boolean
}

const ScanMarkerCTA: React.FunctionComponent<Props> = (props) => {
  const {className, visible, isQRCodeScanned} = props
  const visibleName = visible ? 'show' : 'hide'
  const classNames = `${className} ${visibleName} `
  const label = isQRCodeScanned ? 'Please hold your device steady' : 'Please point your device to a qr-code'

  return (
    <div className={classNames}>
      <div className="popup">
        <label>{label}</label>
      </div>
    </div>
  )
}

export default styled(ScanMarkerCTA)`
  pointer-events: none;
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 100vw;
  z-index: 100;
  display: flex;
  flex-direction: column;
  -moz-box-pack: start;
  align-items: center;

  
  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }

  .popup {
    display: flex;
    width: 356px;
    height: 124px;
    padding-right: 14px;
    margin-bottom: -20px;
    border: medium none;
    background: rgb(48, 60, 73);
    transform: translate(0px);
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;

    padding: 10px 30px;
    font-weight: 50px;

    background: rgba(217, 217, 217, 0.46);
    backdrop-filter: blur(50px);
    border-radius: 24px;



    label {
      padding: 20px 10px;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }
  }
`
