import * as THREE from 'three'

function polarToCartesian(angleInDegrees: number, radius: number) {
    const x = Math.cos(angleInDegrees * Math.PI / 180) * radius
    const y = Math.sin(angleInDegrees * Math.PI / 180) * radius

    return new THREE.Vector3(x, y, 0)
}

export function circularLayout(index: number, count: number, startingAngleInDegrees: number, distance: number) {
    // todo make it sweet
    const delta = 50
    const angleInDegrees = startingAngleInDegrees + delta * index
    return polarToCartesian(angleInDegrees, distance)
}

export function phyllotaxisLayout(index: number, minDistance:number) {
    const phi = 137.5
    const distanceUnit = 30

    const angle = phi * index
    const distance = minDistance + distanceUnit * index

    return polarToCartesian(angle, distance)

}