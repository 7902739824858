import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { AppStyle } from './utils/AppStyle'

const rootElement = document.getElementById('root')

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <>
      <AppStyle />
      <App />
    </>
  )
}
