import React from 'react'
import * as THREE from 'three'
import { CoreFlower } from '../model/coreFlower'
import { QRCodeStatus, qrdisplayPipelineModule, qrprocessPipelineModule } from './qrCodeReader'

export const useImageTargets = (
    canvasId: string,
    targetNameToContent: { [key: string]: CoreFlower },
    onStart: ({ }) => void,
    onDetected?: (coreFlower: CoreFlower) => void,
    onLost?: (coreFlower: CoreFlower) => void,
    onQrCodeStatusUpdate?: (detectedQRCode:QRCodeStatus)=>void
) => {

    const onShowTarget = (input: ImageMsgProcessInput) => {
        const { name, detail } = input
        const coreFlower = targetNameToContent[detail.name]
        if(!coreFlower)
            return

        const object3d = coreFlower.rootObject
        if (!object3d)
            return

        object3d.position.copy(detail.position)
        object3d.quaternion.copy(detail.rotation)
        object3d.scale.set(detail.scale, detail.scale, detail.scale)
        object3d.visible = true

        onDetected && onDetected(coreFlower)
    }

    const onHideTarget = (input: ImageMsgProcessInput) => {
        const { name, detail } = input
        const coreFlower = targetNameToContent[detail.name]
        if (!coreFlower)
            return

        const object3d = coreFlower.rootObject
        if (!object3d)
            return

        onLost && onLost(coreFlower)
    }


    React.useEffect(() => {

        const imageTargetPipelineModule = () => {


            return {
                // Camera pipeline modules need a name. It can be whatever you want but must be
                // unique within your app.
                name: 'threejs-image-targets',

                // onStart is called once when the camera feed begins. In this case, we need to wait for the
                // XR8.Threejs scene to be ready before we can access it to add content. It was created in
                // XR8.Threejs.pipelineModule()'s onStart method.
                onStart,

                // Listeners are called right after the processing stage that fired them. This guarantees that
                // updates can be applied at an appropriate synchronized point in the rendering cycle.
                listeners: [
                    { event: 'reality.imagefound', process: onShowTarget },
                    { event: 'reality.imageupdated', process: onShowTarget },
                    { event: 'reality.imagelost', process: onHideTarget },
                ],
            } as CameraPipelineModule
        }

        const onxrloaded = () => {
            window.THREE = THREE

            // XR8.XrController.configure({ disableWorldTracking: false })

            XR8.addCameraPipelineModules([  // Add camera pipeline modules.
                    // Existing pipeline modules.
                    XR8.CameraPixelArray.pipelineModule({luminance: true, maxDimension: 640}),  // Provides pixels.
                    XR8.GlTextureRenderer.pipelineModule(),  // Draws the camera feed.
                    XR8.Threejs.pipelineModule(),                // Creates a ThreeJS AR Scene.
                    XR8.XrController.pipelineModule(),           // Enables SLAM tracking.
                    XRExtras.AlmostThere.pipelineModule(),       // Detects unsupported browsers and gives hints.
                    XRExtras.FullWindowCanvas.pipelineModule(),  // Modifies the canvas to fill the window.
                    XRExtras.Loading.pipelineModule(),           // Manages the loading screen on startup.
                    XRExtras.RuntimeError.pipelineModule(),      // Shows an error image on runtime error.
                    // Custom pipeline modules.
                    qrprocessPipelineModule(),  // Scans the image for QR Codes
                    qrdisplayPipelineModule(onQrCodeStatusUpdate), // Displays the result of QR Code scanning.
                    imageTargetPipelineModule(),  // Places 3d model over detected image targets.
            ])


            const canvas = document.getElementById(canvasId)
            // Open the camera and start running the camera run loop.
            XR8.run({ canvas, allowedDevices: XR8.XrConfig.device().MOBILE, })
        }

        function load() {
            XRExtras.Loading.showLoading({ onxrloaded })
        }

        function onPageLoaded() {
            if (window.XRExtras)
                load()
            else
                window.addEventListener('xrextrasloaded', load)
        }

        if (document.readyState === 'complete') {
            onPageLoaded()
        } else {
            window.addEventListener("load", onPageLoaded);
            return () => window.removeEventListener("load", onPageLoaded);
        }

    }, []);
}