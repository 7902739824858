import styled from 'styled-components'
import HotspotDetailWidget from '../widget/HotspotDetailWidget'
import ScanMarkerCTA from '../widget/ScanMarkerCTA'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useImageTargets } from '../components/useImageTargets'
import { Database } from '../model/scene'
import { CoreFlower } from '../model/coreFlower'

import { levelInit, levelSetActiveHotspot, levelSetupCssRenderer, levelUpdateCoreFlowerUi, onStartXR, resetLevel, setLevelLocation } from '../components/level'
import { QRCodeStatus } from '../components/qrCodeReader'
import QRCodeViewer from '../widget/QRCodeViewer'
import { HotspotAction, useHotspotStatus } from '../components/useHotspotStatus'
import { useItemFromUrl } from '../components/useItemsFromServer'
import { AppConfiguration } from '../AppConfiguration'

import { Location } from "../model/scene"

type Props = {
  className?: string
}

const targetNameToCoreFlower: { [key: string]: CoreFlower } = {}

const LocationPage: React.FunctionComponent<Props> = (props) => {
  const { className } = props

  const [isXRSessionRunning, setIsXRSessionRunning] = useState<boolean | undefined>(undefined)
  const cssRendererParentRef = useRef<HTMLDivElement | null>(null)

  const { activeHotspot, setActiveHotspot } = useHotspotStatus()
  const [isInitialCTAVisible, setIsInitialCTAVisible] = useState<boolean>(true)
  
  const [qrCodeStatus, setQrCodeStatus] = useState<QRCodeStatus>({ found: false } as QRCodeStatus)

  const locationId = window.location.href.split('?').pop()

  const { item: database } = useItemFromUrl<Database>(`${AppConfiguration.baseUrl}${AppConfiguration.dataPath}`)
  const [location, setLocation] = useState<Location | undefined>(undefined)

  useEffect(() => {

    levelInit(targetNameToCoreFlower, setActiveHotspot)

    const t = navigator.userAgent
    if (t.indexOf('iPhone') > -1 || t.indexOf('iPad') > -1) {
      document.body.classList.add('iOS')
    }
  }, [])

  useEffect(() => {
    levelSetActiveHotspot(activeHotspot)
  }, [activeHotspot])

  useEffect(() => {
    if (cssRendererParentRef.current) {
      levelSetupCssRenderer(cssRendererParentRef.current)
    }
  }, [cssRendererParentRef.current])

  useEffect(function onDatabaseLoaded() {
    const location = database && database.locations.find(l => l.id === locationId)
    setLocation(location)
  }, [database])

  useEffect(function onLocationChanged() {
    setLevelLocation(location)
  }, [location])

  useEffect(function onNewScannedQRCode() {
    if (!database || !qrCodeStatus || !qrCodeStatus.content)
      return

    const newLocationId = qrCodeStatus.content.split('?').pop()
    if (location && newLocationId === location.id)
      return

    const newLocation = database && database.locations.find(l => l.id === newLocationId)
    
    if(!newLocation)
      return
      
    setActiveHotspot({ type: 'HotspotUpdated', newHotspot: undefined } as HotspotAction)
    resetLevel()
    setLocation(newLocation)
  }, [qrCodeStatus, database, location])

  function startXRSession() {
    setIsXRSessionRunning(true)
    onStartXR()
  }

  function onDetected(coreFlower: CoreFlower) {
    if (coreFlower.isDetected)
      return

    setIsInitialCTAVisible(false)

    levelUpdateCoreFlowerUi(coreFlower, true)
  }

  function onLost(coreFlower: CoreFlower) {
    levelUpdateCoreFlowerUi(coreFlower, false)
  }

  function resetAllDetectedMarkers() {
    for (const [markerId, coreFlower] of Object.entries(targetNameToCoreFlower)) {
      coreFlower.lastActivationTime = Number.NEGATIVE_INFINITY
      coreFlower.isDetected = false
      levelUpdateCoreFlowerUi(coreFlower, false)
    }
  }

  function onQrCodeStatusUpdate(status: QRCodeStatus) {
    setQrCodeStatus(status)
  }

  useImageTargets('camera-feed', targetNameToCoreFlower, startXRSession, onDetected, onLost, onQrCodeStatusUpdate)


  function onCloseHotspotDetailWidget() {
    setActiveHotspot({ type: 'HotspotUpdated', newHotspot: undefined } as HotspotAction)
  }

  function onToggleXR() {
    if (isXRSessionRunning === undefined)
      return
    const isRunning = !XR8.isPaused()
    if (isRunning) {
      XR8.pause()

      const { scene, renderer, cameraTexture } = XR8.Threejs.xrScene()
      scene.background = cameraTexture
      renderer.autoClear = true

    } else {
      XR8.resume()
      setTimeout(resetAllDetectedMarkers, 1000)
    }
    setIsXRSessionRunning(!isRunning)
  }

  const toggleText = isXRSessionRunning === undefined ? 'starting session...' : isXRSessionRunning ? 'Pause' : 'Resume'
  const cornerOverlayVisibleClassName = isInitialCTAVisible ? 'show' : 'hide'

  return (
    <div className={className}>
      <ScanMarkerCTA visible={isInitialCTAVisible} isQRCodeScanned={qrCodeStatus.found} />
      <div className="cssRendererParent" ref={cssRendererParentRef}></div>
      <div className="overlay">
        <div className="sceneTitle">
          <label> {location && location.title} </label>
        </div>
        <div className="middle">
          <div className="toggle-xr-button" onClick={onToggleXR}>
            <span>{toggleText}</span>
          </div>
        </div>
        <div className={`corner-overlay ${cornerOverlayVisibleClassName}`}>
          <div className="corner-area">
            <div className="corner is-top is-left"></div>
            <div className="corner is-top is-right"></div>
            <div className="corner is-bottom is-left"></div>
            <div className="corner is-bottom is-right"></div>
          </div>
        </div>
      </div>
      <canvas id="camera-feed"></canvas>
      <HotspotDetailWidget hotspot={activeHotspot} onClose={onCloseHotspotDetailWidget} />
    </div>
  )
}

export default styled(LocationPage)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  pointer-events: none;
  overflow: hidden;

  .overlay {
  	position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;

    .sceneTitle {
      padding: 20px;
      font-size: 24px;
      font-weight: 800;
      color: white;
      text-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
      letter-spacing: 0.02em;
    }

    /* Add padding to push up above brower toolbar. 😴 */
    body.iOS & .footer {
      padding-bottom: 100px;
    }

    .middle {
      text-align: left;
      display: flex;
      flex-direction: column;

      .toggle-xr-button {
        display: inline-block;
        pointer-events: all;
        cursor: pointer;
        margin: 0;
        padding: 0px 0px;

        span {
          display: inline-block;
          margin: 0 20px 20px 20px;
          padding: 10px 20px;
          text-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 25px;
          border: 1px solid white;
          color: white;
        }
      }
    }
    .corner-overlay {
      pointer-events: none;
      width: 100vw;
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;

      transition: opacity 300ms ease;

      &.show{
        opacity: 1;
      }

      &.hide{
        opacity: 0;
      }

      .corner-area {
        position: relative;
        width: 50vw;
        height: 40vw;

        .corner {
          position: absolute;
          content: ' ';
          width: 24px;
          height: 24px;
        }

        .is-top {
          top: 0;
          border-top: 2px solid rgba(255, 255, 255, 1);
        }

        .is-bottom {
          bottom: 0;
          border-bottom: 2px solid rgba(255, 255, 255, 1);
        }

        .is-left {
          left: 24px;
          border-left: 2px solid rgba(255, 255, 255, 1);
        }

        .is-right {
          right: 24px;
          border-right: 2px solid rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .cssRenderer {
    position: fixed;
    top: 0px;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
  }

  .core-cta {
    font-size: 24px;
    font-weight: 700;
    line-height: 64px;
    text-transform: uppercase;
    text-align: center;
    color: #002b49;
    width: 274px;
    height: 64px;
    background-color: white;
    mask-position: center center;
    mask-size: cover;
    mask-image: url('./images/flag.svg');

    transition: opacity 500ms ease-in-ease-in-out;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }

  .sprite {
    color: white;
    cursor: default;
    border-radius: 25px;
    transition: opacity 300ms ease-in;
    line-break: auto;

    .sprite-image {
      position: absolute;
      left: -1%;
      top: -1%;
      width: 102%;
      height: 102%;

      &.background  {
        background-color: black;
        opacity: 0.3;
      }
    }
    .sprite-container {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sprite-label {
      font-family: -apple-system, helvetica, sans-serif;
      text-align: center;
      line-height: 100%;
      text-transform: uppercase;
      font-weight: bold;
      color: white;
    }

    &.hide {
      opacity: 0;
    }

    &.show {
    }

    &.normal {
      font-size: 15px;
      width: 112px;
      height: 112px;
      opacity: 0;
    }

    &.bloomed {
      font-size: 26px;
      width: 250px;
      height: 250px;
      box-shadow: 0 0 0 7px #00cfff;
      opacity: 1;
    }

    &.selected {
      color: #002f4f;
      border: 7px solid white;
    }
  }
`