import React from 'react'
import styled from 'styled-components'
import { Hotspot } from '../model/scene'

type Props = {
  className?: string
  hotspot?: Hotspot
  onClose?: () => void
  onNext?: () => void
}

const HotspotDetailWidget: React.FunctionComponent<Props> = (props) => {
  const { className, hotspot, onClose, onNext } = props

  const [lastActiveHotspot, setLastActiveHotspot] = React.useState<Hotspot | undefined>(undefined)

  React.useEffect(() => {
    if (hotspot) setLastActiveHotspot(hotspot)
  }, [hotspot])

  const visibleName = hotspot ? 'show' : 'hide'
  const title = lastActiveHotspot ? lastActiveHotspot.title : ''
  const detailedTitle =
    lastActiveHotspot ?
      lastActiveHotspot.detailedTitle !== undefined ?
        lastActiveHotspot.detailedTitle
        : lastActiveHotspot.title
      : ''
  const description = lastActiveHotspot ? lastActiveHotspot.description : ''
  const category = lastActiveHotspot ? lastActiveHotspot.category : ''
  const classNames = `${className} ${visibleName}`

  const imageSrc = hotspot && hotspot.iconUrl ? hotspot.iconUrl : './images/ctrlX-item.png'

  const onOpenApp = (hotspot?: Hotspot) => {
    if (!hotspot || !hotspot.appUrl)
      return

    window.open(hotspot.appUrl)
  }

  const onCloseClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClose && onClose()
  }


  return (
    <div className={classNames}>
      <div className="header">
        <button className="close" onClick={onCloseClicked} />
      </div>
      <div className="content">
        <div className="icon-title-container">
          {
            hotspot && hotspot.iconUrl === undefined &&
            <div className='icon-title'>{title}</div>
          }
          <img src={imageSrc}></img>
        </div>
        <div className='text'>
          <div className='category'>{category}</div>
          <h2>{detailedTitle}</h2>
          <p>{description}</p>
          <button onClick={() => onOpenApp(lastActiveHotspot)} > APP STORE </button>
        </div>
      </div>
    </div>
  )
}

export default styled(HotspotDetailWidget)`
  flex: 1;
  position: absolute;
  top: 0;
  left: 100vw;
  min-height: 117px;
  width: 400px;
  max-width: 100vw;

  pointer-events: all;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 1);
  color: #002b49;
  padding: 10px;

  display: flex;
  gap: 10px;
  flex-direction: column;
  transition: transform 250ms ease-in-out;

  font-size: 16px;

  .header {
    position: relative;
    height: 30px;
    flex: 0;
    color: #002b49;

    button.close {
      position: absolute;
      right: 10px;
      top: 7px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: #002b49;
      mask-position: center center;
      mask-size: cover;
      border: none;
      outline: none;
      mask-image: url('./images/close.svg');
    }
  }

  .content {
    height: 0;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-right: 30px;
    gap:20px;



    .text {
      .category {
        font-size: 0.625em;
        color: #00ccff;
      }

      h2 {
        margin: 0;
        font-weight: 600;
        font-size: 1em;
      }
      .p {
        font-size: 0.875em;
      }

      button{
        background-color: #5a7c91;
        color: white;
        border: none;
        outline: none;
        border-radius: 4px;
        padding: 5px 10px;
        line-height: 100%;

        &::after {
          content: 'a';
          color: transparent;
          display: inline-block;
          width: 15px;
          background-size: contain;
          background-image: url('./images/export.svg');
          height: 15px;
          vertical-align: text-bottom;
          margin-left: 5px;
        }
        img {
          width: 10px;
        }
      }
    }
  
    .icon-title-container{
      position: relative;
      min-width: 80px;
      min-height: 80px;
      width: 80px;
      height: 80px;
      left: 0;
      bottom: 20%;
      
      img {
        width: 100%;
        height: 100%;
        box-shadow: 0 4px 8px 0 rgba(187,187,187,0.2),0 6px 20px 0 rgba(187,187,187,0.2);
        border-radius: 6px;
      }
      
    .icon-title {
      position: absolute;
      font-family: -apple-system, helvetica, sans-serif;
      text-align: center;
      line-height: 100%;
      text-transform: uppercase;
      font-weight: bold;
      color: white;
      line-break:auto;
      width: 75px;
      height: 70px;
      font-size: 10px;
      top: 30%;
    }      
  }
    
    
  }  


  &.show {
    transform: translateX(-100%);
  }

  &.hide {
    transform: translateX(0);
  }
`
