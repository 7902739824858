import React from 'react'
import styled from 'styled-components'
import LocationThreeJS from './pages/LocationPage'

type Props = {
  className?: string
}

const App: React.FunctionComponent<Props> = (props) => {
  return (
    <div className={props.className}>
      <LocationThreeJS />
    </div>
  )
}
export default styled(App)`
  font-family: -apple-system, helvetica, sans-serif;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
`
