import * as THREE from 'three'

import { Hotspot, Location } from "../model/scene"
import { phyllotaxisLayout } from '../components/layout'
import { CoreFlower, FlowerAnimation } from '../model/coreFlower'
import { createSceneComponent } from './levelComponents'
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer'
import { HotspotAction } from './useHotspotStatus'
import { easeInOutSine } from './easing'
import { lerp } from 'three/src/math/MathUtils'
import { disposeObjectTree } from './threeJsUtils'

let isLoaded: boolean = false
let location: Location | undefined
let targetNameToCoreFlower: { [key: string]: CoreFlower }

let hotspotElements: { id: string, dom: HTMLElement }[] = []
let flowerAnimations: FlowerAnimation[] = []
let xrScene: THREE.Scene | undefined
let xrCamera: THREE.Camera | undefined
let xrRenderer: THREE.Renderer| undefined


let cssRenderer: CSS3DRenderer = new CSS3DRenderer

const pointer = new THREE.Vector2()
const raycaster = new THREE.Raycaster()

let trySetActiveHotspot: React.Dispatch<HotspotAction>

export function levelInit(
    newTargetNameToCoreFlower: { [key: string]: CoreFlower },
    newTrySetActiveHotspot: React.Dispatch<HotspotAction>,
) {
    targetNameToCoreFlower = newTargetNameToCoreFlower
    trySetActiveHotspot = newTrySetActiveHotspot

    animateLevel()
    window.addEventListener('resize', onWindowResize)
    document.addEventListener('pointerup', onWindowClick)
}

export function levelSetupCssRenderer(element: HTMLElement)
{
    cssRenderer.domElement.className = 'cssRenderer'
    cssRenderer.setSize(window.innerWidth, window.innerHeight)
    element.appendChild(cssRenderer.domElement)
}

export function levelSetActiveHotspot(activeHotspot: Hotspot | undefined) {
    const activeId = activeHotspot ? activeHotspot.id : ''
    hotspotElements.map(({ id, dom }) => {
        const isSelected = id === activeId
        if (isSelected)
            dom.classList.add('selected')
        else
            dom.classList.remove('selected')
    })
}

export function resetLevel() {
    hotspotElements.splice(0, hotspotElements.length)
    flowerAnimations.splice(0, flowerAnimations.length)

    for (var prop in targetNameToCoreFlower) {
        if (targetNameToCoreFlower.hasOwnProperty(prop)) {
            delete targetNameToCoreFlower[prop];
        }
    }

    xrScene && disposeObjectTree(xrScene, { removeFromParent: true, destroyGeometry: true, destroyMaterial: true })

    location = undefined
    isLoaded = false
}

export function setLevelLocation(newLocation: Location | undefined)
{
    location = newLocation
    if(xrScene && location)
    {
        levelLoadLocation()
    }
}

export function onStartXR() {
    const { scene: xr8Scene, camera: xr8Camera, renderer: xr8Renderer } = XR8.Threejs.xrScene()  // Get the 3js scene from XR

    xrScene = xr8Scene
    xrCamera = xr8Camera
    xrRenderer = xr8Renderer


    // move camera to hide hotspots initially
    xr8Camera.position.set(0, 3, 0)
    // Sync the xr controller's 6DoF position and camera parameters with our scene.
    XR8.XrController.updateCameraProjectionMatrix({
        origin: xr8Camera.position,
        facing: xr8Camera.quaternion,
    })

    levelLoadLocation()
}


function levelLoadLocation() {
    if(isLoaded  || !xrScene || !location)
        return

    const imageTargets = location.scenes.map(s=>s.marker_id)
    XR8.XrController.configure({ imageTargets })

    const locationId = location.id
    location.scenes.map(s => {
        const {
            sceneRoot,
            hotspotElements: sceneHotspotElements,
            coreFlower
        } = createSceneComponent(s, locationId)

        xrScene && xrScene.add(sceneRoot)
        hotspotElements = [...hotspotElements, ...sceneHotspotElements]
        levelUpdateCoreFlowerUi(coreFlower)
        targetNameToCoreFlower[s.marker_id] = coreFlower
    })

    xrScene.add(new THREE.AmbientLight(0xffffff, 3))

    isLoaded = true
}

export function levelUpdateCoreFlowerUi(coreFlower: CoreFlower, isDetected?: boolean, isBloomed?: boolean) {
    const isActive = XR8.isPaused() ? coreFlower.isActive : isCoreFlowerActive(coreFlower) 
    coreFlower.isActive = isActive

    if (isDetected !== undefined && coreFlower.isDetected !== isDetected) {
        coreFlower.isDetected = isDetected
    }

    isBloomed = isActive && isBloomed

    if (isBloomed !== undefined && coreFlower.isBloomed !== isBloomed) {
        coreFlower.isBloomed = isBloomed
        removeExistingAnimation(coreFlower)
        const targetDistance = isBloomed ? 500 : 0
        flowerAnimations.push(
            {
                coreFlower,
                isPetalVisibleEndOfAnimation: isBloomed,
                startDistance: coreFlower.currentDistance,
                targetDistance,
                transitionDuration: 300,
                transitionStartTime: Date.now(),
                easing: easeInOutSine
            } as FlowerAnimation)
    }

    coreFlower.ctaDom.className = (coreFlower.isActive && !coreFlower.isBloomed) ? 'core-cta show' : 'core-cta hide'
    coreFlower.core.traverse(o=>o.visible = isActive);

    coreFlower.items.map(i => {

        if (isActive) {
            i.label.element.classList.add('show')
            i.label.element.classList.remove('hide')
        }
        else {
            i.label.element.classList.remove('show')
            i.label.element.classList.add('hide')
        }

        if (coreFlower.isBloomed) {
            i.label.element.classList.add('bloomed')
            i.label.element.classList.remove('normal')
        }
        else {
            i.label.element.classList.remove('bloomed')
            i.label.element.classList.add('normal')
        }
    })

    trySetActiveHotspot({ type: 'CoreFlowerUpdated', coreFlower } as HotspotAction)

}

function animateLevel()
{
    requestAnimationFrame( animateLevel );
    update()
}

function onWindowResize() {

    cssRenderer.setSize(window.innerWidth, window.innerHeight)
}


function onWindowClick (event: any) {

    if(!xrCamera || !xrScene)
        return

    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(pointer, xrCamera)
    const intersects = raycaster.intersectObjects(xrScene.children, true)

    if (intersects.length <= 0)
        return

    const userData = intersects[0].object.userData
    const type = userData['type']
    const id = userData['id']

    if(type === 'core')
    {
        onCoreClicked(id)
    }
    else if(type === 'hotspot')
    {
        onHotspotClicked(id)
    }
}

function onHotspotClicked (hotspotId: string) {
    const foundHotspot = hotspotId && location && location.scenes.flatMap((s) => s.hotspots).find((h) => h.id === hotspotId)
    if (!foundHotspot)
        return
    trySetActiveHotspot({ type: 'HotspotUpdated', newHotspot: foundHotspot } as HotspotAction)
}

function onCoreClicked (coreId: string) {
    const coreFlower = targetNameToCoreFlower[coreId]
    if (coreFlower === undefined)
        return

    const isBloomed = !coreFlower.isBloomed
    levelUpdateCoreFlowerUi(coreFlower, undefined, isBloomed)
}

function removeExistingAnimation(coreFlower: CoreFlower) {
    const existingAnimationIndex = flowerAnimations.findIndex(a => a.coreFlower.id === coreFlower.id)
    if (existingAnimationIndex === -1)
        return
    flowerAnimations.splice(existingAnimationIndex, 1)
}

function update(){
    updateAnimation()
    updateCoreFlowerVisibility()
    render()
}

function render(){
    if(!xrScene || !xrCamera || !xrRenderer)
        return

    cssRenderer.render(xrScene, xrCamera)

    if(XR8.isPaused())
    {
        xrRenderer.render(xrScene, xrCamera)
    }
}

function updateAnimation() {
    const animationsToRemove: number[] = []
    flowerAnimations.forEach((animation, animationIndex) => {
        const elapsedTime = Date.now() - animation.transitionStartTime
        let t = elapsedTime / animation.transitionDuration
        const isLastFrame = t > 1
        t = clamp(t, 0, 1)

        t = animation.easing(t)
        const distance = lerp(animation.startDistance, animation.targetDistance, t)
        animation.coreFlower.currentDistance = distance
        animation.coreFlower.items.map((item, itemIndex) => {
            const labelPosition = phyllotaxisLayout(itemIndex, distance)
            item.label.position.set(labelPosition.x, labelPosition.y, labelPosition.z)
            item.line.geometry.attributes.instanceEnd.setXYZ(0, labelPosition.x, labelPosition.y, labelPosition.z)
            item.line.geometry.attributes.instanceEnd.needsUpdate = true

            item.clickableObject.userData['type'] = 'hotspot'
            item.line.visible = true
        })

        if (isLastFrame) {
            if (!animation.isPetalVisibleEndOfAnimation) {
                animation.coreFlower.items.map(item => {
                    item.clickableObject.userData['type'] = ''
                    item.line.visible = false
                })
            }
            animation.coreFlower.currentDistance = animation.targetDistance
            animationsToRemove.push(animationIndex)
        }
    })

    animationsToRemove.map(a => {
        flowerAnimations.splice(a, 1)
    })
}

function updateCoreFlowerVisibility() {
    if(!xrCamera)
        return

    if(XR8.isPaused())
        return

    const maximumDistanceToCamera = 0

    for (const [markerId, coreFlower] of Object.entries(targetNameToCoreFlower)) {
        const prevActive = coreFlower.isActive

        const distanceToCamera = xrCamera.position.distanceTo(coreFlower.rootObject.position)

        if (coreFlower.isDetected || (prevActive && distanceToCamera < maximumDistanceToCamera)) {
            coreFlower.lastActivationTime = performance.now()
        }

        const isActive = isCoreFlowerActive(coreFlower)
        if(prevActive !== isActive)
        {

            levelUpdateCoreFlowerUi(coreFlower)
        }
    }
}

function isCoreFlowerActive(coreFlower: CoreFlower) {
    const coreFlowerHideTimeoutDuration = 5

    const timeSinceLastActivation = (performance.now() - coreFlower.lastActivationTime) / 1000
    const isActive = timeSinceLastActivation < coreFlowerHideTimeoutDuration
    return isActive
}


function clamp(x: number, min:number, max:number) : number
{
    return x < min ? min : x > max ? max : x
}