import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'
import { Line2 } from 'three/examples/jsm/lines/Line2'

import { Hotspot, Scene } from "../model/scene"
import { CoreFlower, Item } from '../model/coreFlower'


export function createSceneComponent(scene: Scene, locationId: string) {
    // scene hierarchy:
    // -xrScene                     (root)
    //   \_ sceneRoot               (follows image marker)
    //     \_ canvasScale           (scales the content)
    //       \_sceneOriginObject    (adds scene offset)
    //         \_label              (hotspot label)
    //         \_line               (connection line from origin)
    //         ...

    const unitScale = 400

    // create root element
    const sceneRoot = new THREE.Group()
    sceneRoot.position.set(0, 0, -1000)

    const canvasScale = new THREE.Group()
    canvasScale.scale.multiplyScalar(1 / unitScale)
    sceneRoot.add(canvasScale)

    const sceneOriginDom = document.createElement('div')
    const sceneOriginObject = new CSS3DObject(sceneOriginDom)
    const sceneOrigin = scene.offset ? scene.offset : { x: 0, y: 0, z: 0 }
    sceneOriginObject.position.set(sceneOrigin.x, sceneOrigin.y, sceneOrigin.z)
    sceneOriginObject.scale.set(scene.scale, scene.scale, scene.scale)
    canvasScale.add(sceneOriginObject)

    const modelParent = new THREE.Group()
    sceneOriginObject.add(modelParent)


    const loader = new GLTFLoader();
    loader.load('assets/mockRepositioned.glb', function (gltf) {
        const model = gltf.scene
        model.scale.multiplyScalar(unitScale)

        model.traverse(node => {
            if(node.type === 'Mesh')
            {
                node.userData['type'] = 'core'
                node.userData['id'] = scene.marker_id
                node.visible = false
            }
        })

        modelParent.add(model);
    }, undefined, function (error) {
        console.error(error);
    });

    const ctaDivDom = document.createElement('div')
    ctaDivDom.textContent = 'Tap to explore'
    ctaDivDom.className = 'core-cta'
    const ctaObject = new CSS3DObject(ctaDivDom)
    ctaObject.position.set(250, 0,-50)
    sceneOriginObject.add(ctaObject)
    sceneOriginDom.appendChild(ctaDivDom)

    const clickableObject = createCube(250, 50, 100)
    clickableObject.userData['type'] = 'core'
    clickableObject.userData['id'] = scene.marker_id
    clickableObject.visible = false
    ctaObject.add(clickableObject);


    const items = [] as Item[]
    const hotspotIds = [] as string[]
    const coreFlower = {
        id: scene.marker_id,
        hotspotIds: hotspotIds,
        rootObject: sceneRoot,
        core: modelParent,
        ctaDom: ctaDivDom,
        items: items,
        isBloomed: false,
        isDetected: false,
        isActive: false,
        currentDistance: 0,
        lastActivationTime: Number.NEGATIVE_INFINITY
    } as CoreFlower

    const hotspotElements: { id: string, dom: HTMLElement }[] = []
    //create hotspots
    scene.hotspots.map((h, i) => {

        const hotspotId = `${locationId}-${scene.marker_id}-${i}`
        h.id = hotspotId

        const {
            dom, label, line, clickableObject
        } = createHotspotComponent(h)
        hotspotElements.push({ id: h.id, dom: dom })
        sceneOriginObject.add(label)
        sceneOriginObject.add(line);

        hotspotIds.push(hotspotId)
        items.push({ label, line, clickableObject })
    })

    return { 
        sceneRoot, hotspotElements, coreFlower
    }
}

function createHotspotComponent(hotspot: Hotspot) {
    const dom = document.createElement('span')
    dom.className = 'sprite'

    const backgroundImage = hotspot.iconUrl ? hotspot.iconUrl : './images/ctrlX-item.png'
    const imageDom = document.createElement('img')
    imageDom.className = 'sprite-image'
    imageDom.src = backgroundImage
    dom.appendChild(imageDom)

    if (hotspot.iconUrl === undefined) {
        const containerDom = document.createElement('span')
        containerDom.className = 'sprite-container'
        dom.appendChild(containerDom)

        const titleDom = document.createElement('span')
        titleDom.textContent = hotspot.title
        titleDom.className = 'sprite-label'
        containerDom.appendChild(titleDom)
    }

    const label = new CSS3DObject(dom)
    label.position.set(0, 0, 0)

    const line = createLineComponent(label.position)

    const clickableObject = createCube(200, 200, 100)
    clickableObject.userData['type'] = 'hotspot'
    clickableObject.userData['id'] = hotspot.id
    clickableObject.visible = false
    label.add(clickableObject);
    return {
        dom, label, line, clickableObject
    }
}

function createLineComponent(targetPosition: THREE.Vector3) {
    const lineGeometry = new LineGeometry();
    lineGeometry.setPositions([
        0, 0, 0,
        targetPosition.x, targetPosition.y, targetPosition.z]);

    const lineMaterial = new LineMaterial({

        color: 0x5cc9fa,
        linewidth: 0.01, // in world units with size attenuation, pixels otherwise
        vertexColors: false,

        //resolution:  // to be set by renderer, eventually
        dashed: false,
        alphaToCoverage: true,

    });

    const line = new Line2(lineGeometry, lineMaterial);
    line.computeLineDistances();
    line.scale.set(1, 1, 1);
    line.visible = false

    return line
}

function createCube(sizeX: number, sizeY: number, sizeZ: number) {
    const geometry = new THREE.BoxGeometry(sizeX, sizeY, sizeZ)
    const material = new THREE.MeshBasicMaterial({ color: 0xffabff })
    const cube = new THREE.Mesh(geometry, material)
    return cube
}