// code from: https://github.com/lume/lume/blob/develop/src/utils/three.ts

import * as THREE from 'three'
import { RenderItem } from 'three'

export function isRenderItem(obj: any): obj is RenderItem {
	return 'geometry' in obj && 'material' in obj
}

export function disposeMaterial(obj: THREE.Object3D) {
	if (!isRenderItem(obj)) return

	// because obj.material can be a material or array of materials
	const materials: THREE.Material[] = ([] as THREE.Material[]).concat(obj.material)

	for (const material of materials) {
		material.dispose()
	}
}

export function disposeObject(obj: THREE.Object3D, removeFromParent = true, destroyGeometry = true, destroyMaterial = true) {
	if (!obj) return

	if (isRenderItem(obj)) {
		if (obj.geometry && destroyGeometry) obj.geometry.dispose()
		if (destroyMaterial) disposeMaterial(obj)
	}

	removeFromParent &&
		queueMicrotask(() => {
			// if we remove children in the same tick then we can't continue traversing,
			// so we defer to the next microtask
			obj.parent && obj.parent.remove(obj)
		})
}

type DisposeOptions = Partial<{
	removeFromParent: boolean
	destroyGeometry: boolean
	destroyMaterial: boolean
}>

export function disposeObjectTree(obj: THREE.Object3D, disposeOptions: DisposeOptions = {}) {
	obj.traverse(node => {
		disposeObject(node, disposeOptions.removeFromParent, disposeOptions.destroyGeometry, disposeOptions.destroyMaterial)
	})
}
