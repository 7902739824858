import { createGlobalStyle, css } from 'styled-components/macro'

// import { mobileBreakPointSelector, mobileConstants } from './breakpoints'

export const hoverStyleBlock = css`
  /* transition: text-shadow 250ms ease-in-out; */
  text-shadow: 1px 0 0 currentColor;
`

export const AppStyle = createGlobalStyle`
  :root {
    --color-black: #000000;
    --color-white: #ffffff;
  }

  html {
  }

  body {
    margin: 0;
    padding: 0;
    background: #444;
    width: 100%;
    touch-action: none;
  }

  code {
    font-family: monospace;
    background-color: var(--color-vwAgGrey);
    padding: 5px;
    border-radius: 3px;
    font-weight: bold;
    color: var(--color-secondaryYellow);
  }

  body.is-busy {
    cursor: wait !important;
  }


  #modal-root {
    > div {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5000;

      &:not(:empty) {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  * {
    font-family: -apple-system, helvetica, sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
    /* pointer-events: none ; */

    /* Webkit scrollbars */

    ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      appearance: none;
      background: transparent;
    }

    ::-webkit-scrollbar-track-piece {
      appearance: none;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--color-primaryNewLightGrey)
    }

    ::-webkit-scrollbar-thumb:hover {
      background: var(--color-primaryNewLightGrey);
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--color-primaryNewLightGrey); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 22px solid transparent; /* creates padding around scroll thumb */
    }

    /* Firefox scrollbars */

    scrollbar-color: var(--color-primaryNewLightGrey) transparent;
    scrollbar-width: thin;
  }

  h1 {
    margin: 20px 0 20px 0;
    text-align: left;
    font-size: 28px;
    line-height: 128%;
    font-style: normal;
    font-weight: bold;
    color: var(--color-vwAgAnthracite);
  }

  h2 {
    font-style: normal;
    font-weight: lighter;
    font-size: 28px;
    line-height: 128%;
    color: var(--color-vwAgAnthracite);
  }

  h3 {
    font-style: normal;
    font-weight: lighter;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: 0;
    color: var(--color-vwAgAnthracite);
  }

  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 128%;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: var(--color-vwAgAnthracite);
  }

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 128%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--color-vwAgAnthracite);
  }

  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 128%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: var(--color-vwAgGrey);
  }

  a {
    cursor: pointer;
    font-weight: normal;
    text-decoration: none;
    user-select: none;
    letter-spacing: 0em;
  }

  a,
  a:visited,
  a:hover,
  a:link,
  a:active {
    color: var(--color-vwAgPetrol);
  }

  a:hover {
    ${hoverStyleBlock};
  }

  a.textlink {
    text-decoration: underline;
    text-decoration-line: underline;
    line-height: 21px;
  }

  q,
  p.quote {
    font-style: italic;
    font-size: 22px;
    line-height: 28px;
    color: var(--color-vwAgAnthracite);
  }

  img {
    user-select: none;
    pointer-events: none;
  }

  input[type=text] {

    &::placeholder {
      font-style: italic;
      color: rgba(28, 42, 50, 0.5);
    }
  }

  small {
    font-size: 10px;
  }

  #camera-feed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    /* pointer-events: none; */
  }
`
