import { qrcode } from '../vendor/jsqrcode/src/qrcode'


export type QRCodeStatus =
  {
    found: boolean
    content: string
    viewportPoints: THREE.Vector2[]
    canvasSize: { width: number, height: number }
  }

export type QRProcessResult = {
  found: boolean,
  foundText: string,
  points: THREE.Vector2[]
}

// Define a custom pipeline module. This module scans the camera feed for qr codes, and makes the
// result available to other modules in onUpdate. It requires that the CameraPixelArray module is
// installed and configured to provide a luminance (black and white) image.

const qrprocessPipelineModule = () => {
  return {
    name: 'qrprocess',

    onProcessCpu: ({ processGpuResult }) => {

      // Check whether there is any data ready to process.
      if (!processGpuResult.camerapixelarray || !processGpuResult.camerapixelarray.pixels) {
        return { found: false }
      }

      try {
        const { rows, cols } = processGpuResult.camerapixelarray
        qrcode.width = cols
        qrcode.height = rows
        qrcode.grayscale = () => processGpuResult.camerapixelarray.pixels
        const res = qrcode.process()  // Scan the image for a QR code.
        res.points = res.points.map(
          (p: { x: number, y: number }) => ({ x: p.x / (cols - 1), y: p.y / (rows - 1) })
        )

        return res
      } catch (e) {
        return { found: false }  // jsqrcode throws errors when qr codes are not found in an image.
      }

    },

  } as CameraPipelineModule
}


// Define a custom pipeline module. This module updates UI elements with the result of the QR code
// scanning, and navigates to the found url on any tap to the screen.
const qrdisplayPipelineModule = (onStatusUpdate?: (qrCodeStatus: QRCodeStatus) => void) => {

  const mapToTextureViewport = (pt: THREE.Vector2, vp: any) => ({ x: pt.x * vp.width + vp.offsetX, y: pt.y * vp.height + vp.offsetY })
  let cameraCanvas: any
  let lastSeen = 0
  let lastStatus: QRCodeStatus
  const emptyStatus: QRCodeStatus = {
    found: false,
    content: '',
    viewportPoints: [] as THREE.Vector2[],
    canvasSize: { width: 0, height: 0 }
  }

  function updateLastStatus(status: QRCodeStatus, lastStatus:QRCodeStatus) : QRCodeStatus{
    if(!status)
      return lastStatus

    if(status && lastStatus && lastStatus.content === status.content)
      return lastStatus

    return {
      found: status.found,
      content: status.content,
      viewportPoints: [] as THREE.Vector2[],
      canvasSize: { width: 0, height: 0 }
    } as QRCodeStatus
  }

  function getStatus(qrProcessResult: QRProcessResult, viewport: any): QRCodeStatus {
    const { found, foundText, points } = qrProcessResult
    const viewportPoints = found ? points.map((p: THREE.Vector2) => mapToTextureViewport(p, viewport)) : undefined

    return {
      found: found,
      content: foundText,
      viewportPoints: viewportPoints,
      canvasSize: { width: cameraCanvas.width, height: cameraCanvas.height }
    } as QRCodeStatus
  }

  return {
    name: 'qrdisplay',

    onStart: ({ canvas }) => {
      cameraCanvas = canvas
    },

    onUpdate: ({ processGpuResult, processCpuResult }) => {

      if (!processCpuResult.qrprocess) {

        return

      }

      const { found } = processCpuResult.qrprocess
      const { viewport } = processGpuResult.gltexturerenderer

      if (found)
        lastSeen = Date.now()

      const elapsedTimeSinceLastDetected = Date.now() - lastSeen;
      const keepAlive = !found && elapsedTimeSinceLastDetected < 5000

      let status = emptyStatus

      if (found) {
        status = getStatus(processCpuResult.qrprocess, viewport)
      }
      else if (keepAlive) {
        status = lastStatus
      }

      onStatusUpdate && onStatusUpdate(status)
      lastStatus =  updateLastStatus(status, lastStatus)
    },

  } as CameraPipelineModule

}

export { qrprocessPipelineModule, qrdisplayPipelineModule }