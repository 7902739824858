import React from "react";
import { CoreFlower } from "../model/coreFlower";
import { Hotspot } from "../model/scene";

export type HotspotAction = {
    type: 'HotspotUpdated' | 'CoreFlowerUpdated',
    newHotspot: Hotspot | undefined
    coreFlower: CoreFlower | undefined
}

function newHotspotWhenCoreFlowerUpdates(hotspot: Hotspot | undefined, coreFlower: CoreFlower | undefined)
{
    if (!hotspot || !coreFlower) 
        return hotspot

    const foundHotspotId = coreFlower.hotspotIds.find(hid => hid === hotspot.id)

    if (coreFlower.isBloomed && !foundHotspotId) 
        return undefined

    if (!coreFlower.isBloomed && foundHotspotId) 
        return undefined

    return hotspot
}

function reducer(hotspot:Hotspot | undefined, action:HotspotAction)
{
    switch(action.type){
        case 'HotspotUpdated':
            return action.newHotspot
        case 'CoreFlowerUpdated':
            return newHotspotWhenCoreFlowerUpdates(hotspot, action.coreFlower)
        default:
            throw new Error()
    }
}

export function useHotspotStatus() {
    const [activeHotspot, setActiveHotspot] = React.useReducer(reducer, undefined)

    return { activeHotspot, setActiveHotspot}
}